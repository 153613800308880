.addGrid {
  background-color: var(--whitePrimary);
  border-radius: 8px !important;
  border: 1px solid #dee5ec !important;
  box-shadow: 0px 10px 25px #0000000a !important;
}
.customstyleGrid {
  padding: 20px;
}
.customstyleGrid{
  padding: 20px;
}
