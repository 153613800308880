.App {
  text-align: center;
  display: flex;
}
.Container {
  display: flex;
  padding: 5px;
  margin-left: 20px;
}
.Title {
  font-size: 10px;
  color: var(--tertiary-text);
  text-align: left;
  letter-spacing: 0px;
  color: #6a7888;
  opacity: 1;
  font-family: 'Poppins_Regular';
}
.Content {
  font-size: 12px;
  text-align: left;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: var(--graySecondary);
  text-transform: capitalize;
  opacity: 1;
  font-weight: 500;
  font-family: 'Poppins_medium' !important;
}
.Icon {
  background-color: #f1f6fc;
  padding: 6px 9px;
  border-radius: 30px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 20px;
  width: 15px;
}
.content-container {
  padding-left: 10px;
}
