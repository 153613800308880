.startIcon {
  padding-right: 6px;
  width: 10px;
  filter: invert(75%) sepia(120%) saturate(1585%) hue-rotate(203deg)
    brightness(83%) contrast(65%);
}

.endIcon {
  padding-left: 6px;
  width: 10px;
  filter: invert(75%) sepia(120%) saturate(1585%) hue-rotate(203deg)
    brightness(83%) contrast(65%);
}
.btnTitle {
  white-space: nowrap;
  font-size: 16px;
}
.formBtn {
  background: #2A60BC;
  color: var(--whitePrimary);
  border: none;
  border-radius: 9px;
  padding: 9px 16px;
  font-family: Poppins_semibold;
  font-size: 14px;
  cursor: pointer;
}
