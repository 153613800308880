.sidebar {
  height: 100%;
  width: 3.5rem;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  background-color: #fff;
  transition: 0.5s;
  overflow-x: hidden;
  padding-top: 60px;
  padding-top: 5rem !important;
  border:1px solid #0000000A
}

.sidebar a {
  text-decoration: none;
  font-size: 25px;
  color: #2a60bc;
  display: block;
}

.sidebar a:hover {
  color: #2a60bc;
}

main .sidebar {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.material-icons,
.icon-text {
  vertical-align: middle;
}

.material-icons {
  padding-bottom: 3px;
  margin-right: 30px;
}

#main {
  padding: 16px;
  margin-left: 85px;
  transition: margin-left 0.5s;
}

@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
}

.MuiCollapse-root .MuiCollapse-entered {
  min-height: unset !important;
}

.activeColor:hover {
  color: #2a60bc !important;
}

.activeColor .MuiListItemText-root span {
  color: black;
}

.activeList .MuiListItemText-root span {
  color: #2a60bc;
}
.activeSubList .MuiListItemText-root span {
  color: #2a60bc;
  background: #dee5ec;
  padding: 5px 0px 5px 7px;
  border-radius: 5px 0px 0px 5px;
}

.activeSubList .MuiSvgIcon-root {
  background: #dee5ec;
  color: #2a60bc;
  padding: 5px 2px 5px 7px;
  border-radius: 0px 5px 5px 0px;
}

.activeColor,
.activeSubList {
  display: flex !important;
  align-items: center !important;
}