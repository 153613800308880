.css-1i0la1m-MuiTreeView-root {
  min-width: 100% !important;
}
/* .css-1ex1afd-MuiTableCell-root{
    width: 10%!important;
} */
.statusHeader {
  padding: 20px;
}
.MuiTreeItem-label {
  display: flex !important;
}
.css-tbgmt6-MuiTreeItem-content {
  margin-right: 30px !important;
}
.entityDiv {
  width: 300px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: Poppins_medium;
  font-size: 14px;
}
.effectiveFromDiv {
  width: 120px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: Poppins_medium;
  font-size: 14px;
  color: #001c3c;
}
.effectiveToDiv {
  width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: Poppins_medium;
  font-size: 14px;
  color: #001c3c;
}
.reasonDiv {
  width: 410px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: Poppins_medium;
  font-size: 14px;
  color: #001c3c;
}
.statusDiv {
  width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: Poppins_medium;
  font-size: 14px;
  color: #001c3c;
}

.MuiCollapse-root .MuiCollapse-entered .entityDiv {
  font-family: Poppins_regular !important;
  color: #2a3c50 !important;
}
.MuiCollapse-root .MuiCollapse-entered .effectiveFromDiv {
  font-family: Poppins_regular !important;
  color: #2a3c50 !important;
}
.MuiCollapse-root .MuiCollapse-entered .effectiveToDiv {
  font-family: Poppins_regular !important;
  color: #2a3c50 !important;
}
.MuiCollapse-root .MuiCollapse-entered .reasonDiv {
  font-family: Poppins_regular !important;
  color: #2a3c50 !important;
}
.MuiCollapse-root .MuiCollapse-entered .statusDiv {
  font-family: Poppins_regular !important;
  color: #2a3c50 !important;
}


.MuiCollapse-entered{
    margin-left: 0px!important;
}
.effectiveto {
  padding-left: 60px !important;
}
.reason {
  padding-left: 42px !important;
}
.status {
  padding-left: 1px !important;
}
.toolDiv {
  padding-top: 14px;
}
/* .css-tbgmt6-MuiTreeItem-content .MuiTreeItem-iconContainer{
    margin-top: -25px!important;
} */
.MuiTreeItem-root {
  font-size: 20px !important;
}
.MuiCollapse-wrapperInner .MuiTreeItem-iconContainer{
    margin-left: 15px!important;
} 
.MuiCollapse-wrapperInner .effectiveFromDiv{
    margin-left: -15px!important;
}
.css-vj1n65-MuiGrid-root{
    align-items: center!important;
}
.entityTitle {
  margin-left: -20px !important;
}
.effectivefrom {
  padding-left: 60px !important;
}
