.chargemasterGrid {
  padding-top: 80px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  padding-bottom: 20px !important;
}

.applicableGrid {
  padding-top: 20px !important;
}

.insideGrid {
  margin-top: 0px !important;
}
.chargeGrid {
  margin-top: 20px !important;
}

.btnclk {
  background-color: aqua !important;
  color: white !important;
}

.menubtn {
  padding-top: 3px !important;
}

.btnstyle {
  color: white !important;
}

.statusgrid {
  margin-left: -29px !important;
}

.tabs {
  background-color: white !important;
  border: 1px solid #dee5ec !important;
  border-radius: 8px !important;
}

.statusentitybtn {
  padding: 0px !important;
  font-size: 12px !important;
}

.customStyle {
  display: flex;
}
.Switch {
  padding: 0px 10px 0px 17px;
  color: var(--tertiaryText) !important;
  padding-bottom: 6px;
  font-size: 12px !important;
  font-family: 'Poppins_medium' !important;
  white-space: nowrap;
}
#chargecode{
  text-transform: uppercase;
}
.validBottom{
  height: 110px !important;
}
