.barDiv{
    padding: 10px;
    background-color: var(--background);
    border-right: 2px solid var(--grayQuaternary);
    white-space: nowrap;
    
}
.barText{
font-size: 12.5px !important;
}
.barDiv:first-child{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    text-align: center;
 
}
.barDiv:last-child{
     border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: unset;
    text-align: center;
  

}