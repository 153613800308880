  .tablePackage {
    border: 2px solid var(--background);
    border-collapse: unset !important;
    border-radius: 13px !important;
    width: 110% !important;
    cursor: pointer;
  }

.stickyColumn {
  font-family: 'Poppins_Regular';
  right: 0;
  position: 'sticky';
  z-index: 13;
  border-left:'1px solid #6a7888ab';
}
.tableBody {
  background-color: #ffffff;
}

  .tableBody tr:last-child th:first-child {
    border-bottom-left-radius: 10px;
  }
  .tableBody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
  .tableHeader {
    padding: 10px;
    font-family: Poppins_semibold !important;
  }
  .tableCell {
    font-family: Poppins_medium !important;
    text-align: start !important;
  }
  
  .actionIcon {
    cursor: pointer;
    padding-left: 12px;
  }

  .actionItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .chargeWidth{
    width: 450px!important;
    background: var(--background) !important;
  }
  .packagetableConatiner::-webkit-scrollbar{
    display: none!important;
  }

  