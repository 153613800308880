.dateTextField {
  /* height: 30px; */
  border-radius: 16px;
  background-color: var(--whitePrimary);
}
.dateTextField .MuiOutlinedInput-root {
  border: 2px solid var(--grayQuinary);
  border-radius: 9px;
  padding: 2px 12px 2px 0px;
}
.datePick .MuiOutlinedInput-input .MuiInputBase-input {
  padding: 3px 0px !important;
}
.datePick {
  font-size: 12px !important;
}
.timeTextField {
  border: 2px solid var(--grayQuinary);
  border-radius: 9px;
  padding: 2px 12px 2px 0px;
}
.timeTextField.MuiOutlinedInput-root {
  border: 2px solid var(--grayQuinary);
  border-radius: 9px;
  padding: 2px 12px 2px 0px;
}
.disabled {
  background-color: var(--secondaryBackground);
}
