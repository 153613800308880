.mainGrid{
    padding-top: 80px!important;
    padding-right: 20px!important;
    padding-left: 20px!important;
}
.custombtn{
    text-transform: none!important;
    color:#fff!important;
    background-color: #F1F6FC!important;
}
.custombtnsubmit{
    text-transform: capitalize!important;
    color:#fff!important;
    background-color: #2A60BC!important;
}
.btngrid{
    padding-top: 15px!important;
}
