.dropGrid {
  width: 200px;
  margin-left: -3px;
}
.textField {
  border-right: unset !important;
  text-align: center;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-family: Poppins_Regular;
  color: var(--graySecondary);
  width: 52px;
  padding: 6px 0px 4px 0px;
  margin-top: 6px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 2px solid var(--grayQuinary);
  font-size: 12px;
  font-family: Poppins_medium;
  height: 27px;
}
.textField:focus-visible {
  outline: none;
}
.dropdown {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  font-size: 12px !important;
  font-family: Poppins_medium;
  padding: 2.4px 0px;
}
.labelTitle {
  font-family: Poppins_medium !important;
  font-size: 12px !important;
  color: var(--tertiaryText) !important;
}
