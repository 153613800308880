.appBarBackgroundColor {
    padding: 7px;
}

.MuiAppBar-root {
    z-index: 11 !important;
    box-shadow: none!important;
}

.iconContainer img {
    width: 3rem;
}

.tile_view {
    text-align: start;
    padding-left: 1rem;
}

.tab_flow {
    text-align: end;
    font-size: 0.7rem;
    padding: 0.3rem 1rem 0.3rem 0;
    border-right: 1px solid rgb(204, 204, 204);
}

.profile_container {
    text-align: start;
    padding-left: 1rem;
}

.profile img {
    width: 3rem;
    border-radius: 35px;
}

.sub_profile {
    font-size: 12px;
}

.rotate .down {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.transform {
    transform: rotate(180deg);
}

.iconStyling {
    width: 1.5rem;
    height: 1.5rem;
}

.menuItems .MuiButtonBase-root-MuiMenuItem-root .MuiListItemIcon-root img {
    color: red;
}

.navLink{
    display: flex !important;
    align-items: center;
}