.menuItem .MuiMenuItem-root {
  background: var(--whitePrimary) !important;
  font-family: Poppins_Regular;
}
.labelText {
  color: var(--tertiaryText) !important;
  padding-bottom: 6px;
  font-size: 12px !important;
  font-family: 'Poppins_Regular';
  white-space: nowrap;
}
.inputLabel {
  font-size: 12px;
  color: var(--tertiaryText);
  font-family: 'Poppins_medium';
}
.disable {
  background-color: var(--secondaryBackground);
}
.customDropdown {
  border-radius: 10px !important;
  font-family: Poppins_semiBold !important;
  font-size: 12px;
  border: 2px solid var(--grayQuinary);
}
.customDropdown:focus-visible {
  outline: none !important;
}
.formControl:focus-visible {
  outline: none !important;
}
.css-1yh7mv3-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    display: none!important;
  }
.css-1yh7mv3-MuiFormLabel-root-MuiInputLabel-root{
   display: none!important;
}
.css-1ier95z-MuiButtonBase-root-MuiMenuItem-root.Mui-selected{
  background-color: white!important;
}
.inputlabelText{
  font-family:'Poppins_medium'!important;
  font-size: 13px!important;
  padding-top: 4px!important;
}
