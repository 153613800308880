.Add {
  border-right: 1px solid var(--borderColor);
  text-align: center;
  padding: 5px 6px 6px 6px;
}
.addText {
  white-space: nowrap;
}
.container {
  display: flex;
  background-color: #e7f3ff;
  color: var(--secondaryText);
  width: 110px;
  font-size: 13px;
  border: 1px solid var(--borderColor);
  padding: 1px;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 5px;
}
.dropdownVal .MuiSvgIcon-root {
  padding-top: 4px;
}
.listItem {
  padding: 0px 0px 0px 10px !important;
  cursor: pointer;
  border: unset;
}
.listItem:focus-visible {
  outline: none;
}
.listText .MuiTypography-root {
  font-family: Poppins_Regular !important;
}
.dropdownVal{
  padding: 0px 5px 0px 5px;
}
