.AddContainer{
    padding: 10px 0px;
    display: flex;
}
.DropDown{
    margin: 10px;
}
.AddInput{
    margin: 10px;
    width: 100px;
}
.DiscriptionDropDown{
    width: 330px;
}
.OrderCatelog{
    margin-left: 20px;
}
.RaiseOrder {
    margin-left: 10px;
}
.limit{
    margin-left: 20px;
    width: 100px;
}
.qty{
    width: 100px;
    margin-left: 20px;
}
.LimitType{
    margin-left: 20px;
}
.AddContainer1 {
    padding: 10px 20px;
    display: flex;
}
.addNewContainer{
border: 1px solid #DEE5EC;
    border-radius: 10px;
}
.AddContainers{
    padding: 10px;
}
.multiSaveBtn{
    display: flex;
    justify-content: flex-end;
}
.saveContainer{
    width: 100%;
}
.TableContainer{
    margin-top: 10px;
}