.cardGrid {
  width: 100%;
  border: 1px solid #dee5ec !important;
  padding: 15px;
  border-radius: 10px;
}
.texttitle {
  font-family: Poppins_semibold !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  font-size: 12px !important;
}
.custombtn {
  text-transform: none !important;
  color: #fff !important;
  background-color: #f1f6fc !important;
}
.dividerGrid {
  margin-top: 10px !important;
  margin-bottom: 15px !important;
}
.addNewHead {
  font-size: 12px !important;
  font-family: Poppins_semibold !important;
}
.dividerGrid {
  margin-top: 10px !important;
  margin-bottom: 15px !important;
}
.packageDiv {
  border: 1px solid #dee5ec;
  padding: 10px 10px 20px 10px !important;
  border-radius: 10px !important;
  margin: 10px 10px 10px 5px !important;
}
.chargeTitle {
  margin-top: 10px !important;
  font-weight: bold !important;
}
.tabContent{
  padding: 0px 20px 15px 20px!important;
}
