.searchClass {
  padding-right: 0px !important;
  width: 110px;
}
.valueText {
  padding-top: 7px;
  font-family: Poppins_Regular;
  font-size: 12px;
  text-transform: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.valueTextDiv {
max-height: 100vh!important;
height: 65vh!important;
  overflow: auto;
  padding-top: 5px;
}
.valueTextDiv::-webkit-scrollbar {
  display: none !important;
}
.filterText {
  font-family: Poppins_semibold !important;
  text-align: center;
  width: 100%;
  padding: 9px 0px 9px 18px;
  text-align: left;
}
