.btn {
  float: right;
  right: 10px;
}

.Text {
  font-size: 14px;
}

.delete {
  margin-top: -10px!important;
  /* padding-top: 15px; */
  /* display: flex; */
  /* align-items: flex-start; */
}

.delete .MuiSvgIcon-root {
  filter: invert(27%) sepia(93%) saturate(6562%) hue-rotate(355deg)
    brightness(114%) contrast(124%);
  cursor: pointer;
}

.checkText .MuiSvgIcon-root {
  font-size: 1.9rem !important;
  padding-top: 5px;
}

.additional {
  padding-top: 10px !important;
}
.addCheck .MuiSvgIcon-root {
  font-size: 1.8em;
  padding: 7px 0px;
}
.or{
  margin-top: 30px;
  padding-left: 15px;
}
