.labelTxt {
  color: #6a7888;
  font-size: 12px;
  font-family: 'Poppins_Regular';
}

.inputBox {
  border: 2px solid var(--grayQuinary);
  padding: 11px 0px 10px 10px;
  border-radius: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  font-family: Poppins_semiBold !important;
  color: var(--graySecondary);
}
input:focus {
  outline: none;
}

.errorMsg {
  color: red;
  font-size: 0.7rem;
  margin-top: 0.4rem;
}

.errorBox {
  border: 1px solid red;
}
.disable {
  background-color: var(--secondaryBackground);
}
#ChargeCode {
  text-transform: uppercase;
}
#aliasCode {
  text-transform: uppercase;
}
