.paper {
  border-radius: 0px !important;
  margin-left: -10px !important;
  position: fixed;
  width: 96%;
  z-index: 2;
}

.customstyle {
  font-size: 16px !important;
  color: #001c3c !important;
  font-family: Poppins_semibold !important;
}

.cancelbtn {
  text-transform: capitalize !important;
  color: #6a7888 !important;
  font-size: 16px !important;
  font-family: Poppins_medium !important;
  margin-right: 15px!important;
  padding: 4px 16px!important;
  border:1px solid lightgrey!important;
}

.previousBtn {
  text-transform: capitalize !important;
  font-family: Poppins_medium !important;
  padding: 5px 10px !important;
}

.savebtn {
  text-transform: capitalize !important;
  font-family: Poppins_medium !important;
  padding: 5px 10px !important;
  margin-right: 15px!important;
}

.arrowicon {
  width: 15px !important;
  height: 15px !important;
}

.iconbtn {
  /* margin-left: -25px!important; */
  margin-right: 5px !important;
  padding: 6px !important;
}

.btnGrid{
  padding-right: 15px;
}

.proceed {
  background-color: var(--secondaryButton);
}