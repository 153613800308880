.mainLayout {
  padding: 18px;
  border-radius: 8px;
  background-color: var(--whitePrimary);
  box-shadow: 1px 1px 4px #6a78886e;
}

.cardTitle {
  font-family: Poppins_semibold !important;
  padding-bottom: 20px;
  font-size: 12px;
}
.optionalhead {
  padding-bottom: 10px !important;
}
.tooltipHold {
  padding: 15px 8px 0px 8px;
}
.tooltipHead {
  padding: 0px !important;
}
