.checkLabel {
  font-family: Poppins_Regular !important;
  font-size: 12px !important;
  color: var(--tertiaryText) !important;
}
.checkAlign {
  align-items: center !important;
  justify-content: center;
  display: inline-flex;
}
.alignTop {
  align-items: baseline !important;
  justify-content: center;
  display: inline-flex;
}
.checkLabelText {
  color: var(--tertiaryText) !important;
  font-size: 12px !important;
  white-space: nowrap;
}
