.footer{
    display:flex;
    justify-content: space-between;
    align-items: center;
     padding: 15px 10px 15px 21px;

}
.totalPages{
    font-weight: 600;
    color:#001C3C;
     font-size: 14px;
     font-family: Poppins_Bold!important;
}
.page{
    font-weight: 100;
     color:#001C3C;
     font-size: 14px;
     font-family: Poppins_Regular!important;

}
.pageText{
    margin: 0px;
    padding-right: 5px;
    font-size: 12px;
    color:#6A7888;
     font-family: Poppins_Regular!important;;
    align-items: center;
    display: flex;
    font-weight:15
}


.tableFooter{
    display:flex
}

.paginationDropDown{
    padding: 3px 20px 3px 5px;
    border-radius: 5px; 
    background-color: #fff;
    border:solid 1px #ddd;
   font-family: 'Poppins_Regular' !important;
}
.paginationDropDown:focus-visible{
    outline: none;
}
.paginationDropDown:hover{
    cursor: pointer;
}
.paginationCount{
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 1rem;
}

.arrowIcon{
    background-color:  #fff;
   border:solid 1px #ddd;
    border-radius: 24px ;
    padding: 8px;
   padding: 0px 3px 0px 8px;
    border-radius: 40px;
     cursor: pointer;
}

.paginationDropDownSection{
    display: flex
}
.paginationDropDownSection:hover{
    cursor: context-menu;
}
.option{
   font-family: 'Poppins_Regular' !important;
}