.toolEdit {
  padding-right: 15px;
  cursor: pointer;
}
.toolIcon {
  filter: invert(23%) sepia(0%) saturate(1296%) hue-rotate(271deg)
    brightness(14%) contrast(2%) !important;
  padding-right: 10px;
  cursor: pointer;
}
/* .statusDiv span {
  padding-right: 5px;
} */
.cancelPic {
  width: 10px;
}
.divCancel {
  padding: 5px 4px 4px 5px;
  background: var(--tertiaryBackgroud);
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.divCheck {
  padding: 4px;
  background: var(--secondaryText);
  border-radius: 12px;
  margin-right: 10px;
  cursor: pointer;
}
.reasonHead {
  padding-top: 4px;
}
