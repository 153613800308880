.Container1 {
    background: var(--differentiation-background) 0% 0% no-repeat;
    border: 1px solid var(--line-border-fill-1);
    background: #F1F6FC;
    border: 1px solid #DEE5EC;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    padding: 5px 0px;
    margin-left: 15px;
    margin-top: 15px;
    /* font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.counter {
    background: var(--line-border-fill-1) 0% 0% no-repeat padding-box;
    background: #DEE5EC 0% 0% no-repeat padding-box;
    border-radius: 0px 8px 8px 0px;
    opacity: 1;
    padding: 7px 3px;
    margin: 6px 0px;
}

.CriteriaImg {
    background: var(--line-border-fill-1) 0% 0% no-repeat padding-box;
    background: #DEE5EC 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 7px 10px;
    border-radius: 40px;
    margin: 5px 20px;
}

.addImg {
    padding-top: 5px;
}

.head {
    font-size: 12px;
    color: var(--secondary-text);
    text-align: left;
    letter-spacing: 0px;
    color: #2A3C50;
    opacity: 1;
    padding-bottom: 5px;
}

.content1 {
    color: var(--primary-text);
    text-align: left;
    letter-spacing: 0px;
    color: #001C3C;
    opacity: 1;
    font-size: 12px;
    font-weight: bold;
}

.content-header {
    margin: 3px 40px 0px 5px;
}

.deleteBtn {
    padding: 5px 15px;

}

.DelContainer {
    padding: 7px;
    display: flex;
}