.customSearch {
  background-color: var(--background);
  border: unset;
  padding: 10px;
  border-radius: 6px;
  padding-right: 4rem;
  font-family: Poppins_Regular !important;
  width: 300px;
}

.root {
  display: inline-flex;
  font-family: Poppins_medium;
}
.customSearch:focus-visible {
  outline: none;
}
.searchDiv {
  display: inline-flex;
  background-color: var(--background);
  border-radius: 6px;
  height: 38px;
  width: 100%;
}
.searchIcon {
  padding: 9px 0px 0px 10px;
}
.searchIcon .MuiSvgIcon-root {
  fill: var(--iconColor);
}
input[type='search']::-webkit-search-cancel-button {
  display: none !important;
}
.crossIcon {
  width: 8px;
  padding-right: 10px;
  filter: invert(11%) sepia(28%) saturate(5187%) hue-rotate(208deg)
    brightness(106%) contrast(109%);
  cursor: pointer;
}
