.mainTablePaper {
  /* width: 100%; */
  margin-bottom: 16px;
  border-radius: 8px !important;
  padding-right: 18px;
  border: 1px solid #dee5ec !important;
  box-shadow: 0px 10px 25px #0000000a !important;
}
.tableActionGrid {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 15px;
}

.tableAction {
  display: flex;
}
.tableContainer {
  height: 64vh;
  margin-top: 10px;
  padding-left: 10px;
}
.table {
  border: 2px solid var(--background);
  border-collapse: unset !important;
  border-radius: 13px !important;
  min-width: 750px;
}
.theadRow {
  background: var(--background) !important;
}
.theadRow th:first-child {
  border-top-left-radius: 10px;
}
.theadRow th:last-child {
  border-top-right-radius: 10px;
}
.tableBody tr:last-child th:first-child {
  border-bottom-left-radius: 10px;
}
.tableBody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
.tableHeader {
  padding: 10px;
  font-family: Poppins_semibold !important;
}
.tableCell {
  font-family: Poppins_medium !important;
  text-align: start !important;
}

.btnActionTable .startIcon {
  filter: none !important;
}
.editIcon {
  cursor: pointer;
  padding-left: 12px;
}
.uppercase {
  text-transform: uppercase;
}
.descwidth{
  width: 450px!important;
}
