.container1{
    display: flex;
    border-bottom: 1px solid #c9cdd0;
    padding: 10px 0px 10px 0px;
}
.condition{
    padding: 10px;
    
}
.MainContainer{
    border: 1px solid #c9cdd0;
    border-radius: 10px;
    padding-bottom: 55px;
}
.input1{
    padding: 10px;
    width: 210px;
}
.Btn{
    display: flex;
    float: right;
    right: 10px;
    padding: 5px;
}
.adds{
    padding: 5px;
}
.save{
    padding: 5px;
}
.label{
    text-decoration: none;
}
.inputPackage{
    width: 80px;
    margin-left: 20px;
}
.inputContaainer{
    margin: 10px 5px;
    display: flex;
}
.DropPackage{
    width: 160px;
    margin-left: 20px;
}
