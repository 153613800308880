/* .App {
  background-color: #F3F4F7;
  height: 100vh
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: Poppins_bold;
  src: url(../src/utils/Fonts/Poppins-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Poppins_medium;
  src: url(../src/utils/Fonts/Poppins-Medium.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Poppins_Regular;
  src: url(../src/utils/Fonts/Poppins-Regular.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Poppins_light;
  src: url(../src/utils/Fonts/Poppins-Thin.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Poppins_semibold;
  src: url(../src/utils/Fonts/Poppins-SemiBold.ttf);
  font-weight: bold;
}

.add {
  filter: invert(50%) sepia(106%) saturate(776%) hue-rotate(169deg)
    brightness(75%) contrast(114%);
}
:root {
  --background: #ecf0f7;
  --secondaryBackground: #dee5ec;
  --tertiaryBackgroud: #ffe9e3;
  --borderColor: #bfd3f5;
  --iconColor: #7884938c;
  --grayPrimary: #788493;
  --graySecondary: #2a3c50;
  --grayQuaternary: #1010100f;
  --grayQuinary: #babec387;
  --whitePrimary: #ffffff;
  --primaryButton: #01205c;
  --secondaryText: #2a60bc;
  --tertiaryText: #6a7888;
  --secondaryButton: #ec6a49;
  --error: #ff0000;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(160, 160, 160);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
