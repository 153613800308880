.headerStyle {
  color: var(--whiteTertiary);
  font-weight: bold;
}
.VerticalModalStyling {
  position: fixed;
  top: 10px;
  right: 20px;
  background-color: var(--whitePrimary);
}
.verticalHeader {
  background-color: var(--bluePrimary);
  color: var(--whitePrimary);
  height: 5px;
  justify-content: center;
  display: row;
  justify-content: center;
}
.verticalFooter {
  background-color: var(--bluePrimary);
  color: var(--whitePrimary);
}
.verticalContent {
  height: 280px;
  width: 220px;
}
.modTitle {
  font-size: 14px !important;
  color: var(--primaryText);
  font-family: Poppins_semibold !important;
  padding: 20px 0px 14px 15px !important;
}

.closeModal {
  cursor: pointer;
}
.modalHeadAction {
  display: flex;
  justify-content: space-between;
  padding: 6px 14px 6px 0px;
}
.bulkEditIconDiv {
  width: 28px;
  height: 28px;
  margin: 8px;
  border-radius: 14px;
  border: 1px solid var(--secondaryText);
}
.bulkEditIcon {
  width: 20px;
  padding: 5px;
  cursor: pointer;
}
.spaceRight {
  padding-right: 10px !important;
}
