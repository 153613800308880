.labelContainer{
   background-color: #f1f6fc;
   width: 150px;
   padding: 5px 10px 5px 10px;
   border-radius: 5px;
   color: #537ec9;
   display: flex;
   justify-content:center ;
   align-items: center;
   margin: 10px;
   cursor: pointer;
}
.content{
    font-size: 12px;
    color: #2A60BC;
    font-weight: 600;
}
.img{
    padding-right: 10px;
}
.imageHolder{
    height:13px;
    width:13px;
}