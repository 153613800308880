.drawerTitle {
  padding: 15px 10px !important;
  font-family: Poppins_medium !important;
}
.drawerContent {
  padding: 20px !important;
}
.formField {
  padding: 10px 0px;
}
.drawerAction {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}
.space {
  padding: 0px 0px 15px 0px;
}
