.addIcon {
  filter: invert(68%) sepia(100%) saturate(775%) hue-rotate(188deg)
    brightness(91%) contrast(92%);
}

.addNewDiv {
  padding-top: 5px;
}
.borderBottom {
  border: 1px solid var(--background);
}
